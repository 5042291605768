import React from 'react';
import { connect } from 'react-redux';

import { XIcon, colors } from '@commonsku/styles';
import { BASE_ZINDEX } from '../popup-factory';

import { getPurchaseOrder } from '../selectors';
import { getUserContactsForEmailList } from '../selectors/dropdowns';

import { closePopup } from '../actions/popup';
import { sendPurchaseOrder, fetchPurchaseOrder, SEND_PURCHASE_ORDER_FAILURE } from '../actions/purchase_order';

import EmailList, { getContactIds, getExtraEmails } from './EmailList';
import Select from './Select';

import { getIdentityUtils } from '../utils';
import AutoExpandIFrame from './AutoExpandIFrame';
import config from '../config';
import {isEmpty} from "lodash";

const StormtechTermsOfOrderLink = ({ division_id }) => {
  const url = 'https://www.stormtechperformance.com/support/terms-of-use/';
  return [
    'ce978bc4-a30d-11e2-80a6-1231392238ea', // Stormtech Canada
    'cea002ae-a30d-11e2-80a6-1231392238ea', // Stormtech USA
  ].includes(division_id) && <div className="row">
    <div className="small-12 medium-2 columns"/>
    <div className="small-12 medium-10 columns">
      <a
        href={url} target="_blank" rel="noreferrer"
        style={{ color: colors.primary1.main, fontSize: 'medium', margin: '0 10px' }}
      >View terms on stormtech.com</a>
    </div>
  </div>;
};

class SubmitPOPopup extends React.Component {

  constructor(props) {
    super(props);

    const { hasUserFlags } = getIdentityUtils(props.identity);

    this.state = {
      bcc_me: !hasUserFlags('EMAIL-OPTION-NO-BCC1'),
      bcc_production_rep: !hasUserFlags('EMAIL-OPTION-NO-BCC2'),
      bcc_order_rep: !hasUserFlags('EMAIL-OPTION-NO-BCC3'),
      from_contact_id: props.production_rep_contact_id,
      to_contacts: [],
      message: '',
      has_error: false,
      has_submitted: false
    };

    ['handleToggleBccMe',
     'handleToggleBccProductionRep',
     'handleToggleBccOrderRep',
     'onChangeFrom',
     'onChangeTo',
     'onClose',
     'onSubmit'
    ].forEach(
      method => this[method] = this[method].bind(this)
    );
  }

  handleToggleBccMe(e) {
    this.setState(state => ({ bcc_me: !state.bcc_me }));
  }

  handleToggleBccProductionRep(e) {
    this.setState(state => ({ bcc_production_rep: !state.bcc_production_rep }));
  }

  handleToggleBccOrderRep(e) {
    this.setState(state => ({ bcc_order_rep: !state.bcc_order_rep }));
  }

  onChangeFrom(from_contact_id) {
    this.setState({ from_contact_id });
  }

  onChangeTo(to_contacts) {
    this.setState({ to_contacts });
  }

  onClose() {
    this.props.dispatch(closePopup());
  }

  onSubmit() {
    const { purchase_order } = this.props;
    const { bcc_me, bcc_production_rep, bcc_order_rep, from_contact_id, to_contacts } = this.state;

    let contact_ids = getContactIds(to_contacts);
    let to_contact_extras = getExtraEmails(to_contacts);
    if (bcc_me) {
      contact_ids = contact_ids.concat(this.props.me_contact_id);
    }
    if (bcc_production_rep) {
      contact_ids = contact_ids.concat(this.props.production_rep_contact_id);
    }
    if (bcc_order_rep) {
      contact_ids = contact_ids.concat(this.props.order_rep_contact_id);
    }
    this.props.dispatch(sendPurchaseOrder(purchase_order.purchase_order_id, from_contact_id, contact_ids, to_contact_extras, purchase_order?.allow_resubmit && ! isEmpty(purchase_order.epo_submission_status) ? true : null)).then(action => {
      this.onClose();
      this.props.showEPOPopup();
    });
  }


  renderPurchaseOrderForm( purchaseOrderId ) {
    return (
      <div>
         <AutoExpandIFrame id='PO_form'
          title='PO form'
          src={"https://" + window.location.hostname + `/public_purchase_order.php?id=${purchaseOrderId}`}
          />
      </div>
    )
  }

  renderSubmit() {
    const { identity, purchase_order, user_contacts } = this.props;
    const { from_contact_id, bcc_me, bcc_production_rep, bcc_order_rep, to_contacts } = this.state;
    return (
      <div>
        <div className="row" style={{maxWidth: 'none'}}>
          <div className="small-12 columns">
            <div className='row' style={{maxWidth: 'none'}}>
              <div className='small-11 medium-11 columns'>
            <span style={{fontWeight:'bold', marginLeft: '-15px', fontSize: '1.3rem'}}>Submit PO#{purchase_order.form_number}</span>
            </div>
            <div className='small-1 medium-1 columns' style={{ textAlign: 'right', alignSelf: 'end', cursor:'pointer'}}>
          <XIcon  onClick={this.onClose} color={colors.primary.main} style={{ marginTop: 4, }} />
            </div>
            </div>
            <p>This purchase order will be submitted electronically to {purchase_order?.origin?.division_name ?? purchase_order.supplier_name}.  {! purchase_order?.allow_resubmit ? 'After it is submitted you will not be able to modify it or resubmit it.' : ''}</p>
            <p>If you would also like to send an email to yourself or one of your team members, you can select the appropriate recipients from the fields below.</p>
          </div>
        </div>
        <div className="row" style={{maxWidth: 'none'}}>
          <div className="small-12 medium-2 columns">
            <label>From:</label>
          </div>
          <div className="small-12 medium-10 columns">
            <Select options={user_contacts.map(uc => ({ key: uc.value, value: uc.label }))} value={from_contact_id} change={this.onChangeFrom} />
          </div>
        </div>
        <br/>
        <div className="row" style={{maxWidth: 'none'}} >
          <div className="small-12 medium-2 columns">
            <label>BCC:</label>
          </div>
          <div className="small-12 medium-10 columns">
            <EmailList options={user_contacts} value={to_contacts} onChange={this.onChangeTo} />
          </div>
        </div>
        <div className="row" style={{maxWidth: 'none'}} >
          <div className="small-hide medium-2 columns">
            &nbsp;
          </div>
          <div className="small-12 medium-10 columns">
            <input id="me" type="checkbox" checked={bcc_me} value={bcc_me} onChange={this.handleToggleBccMe} />
            <label htmlFor="me">
              BCC Me
            </label>
            <input id="production-rep" type="checkbox" checked={bcc_production_rep} value={bcc_production_rep} onChange={this.handleToggleBccProductionRep} />
            <label htmlFor="production-rep">
              BCC Production Rep
            </label>
            <input id="order-rep" type="checkbox" checked={bcc_order_rep} value={bcc_order_rep} onChange={this.handleToggleBccOrderRep} />
            <label htmlFor="order-rep">
              BCC Order Rep
            </label>
          </div>
        </div>
        <StormtechTermsOfOrderLink division_id={purchase_order.supplier_id ?? null}/>
        <div className="row" style={{position: 'sticky', top:'-16px',  backgroundColor:'white',  maxWidth: 'none'}}>
          <div className="small-12 columns" >
            <a className="button small" onClick={this.onSubmit} style={{ float: 'right', marginLeft: '1rem', marginRight: '0.5rem',marginTop: '0.3rem' }}>{purchase_order?.epo_submission_status == 'SUCCESS' && purchase_order?.allow_resubmit ? 'Re-submit ePO' : 'Submit ePO'}</a>
            <a className="button small" onClick={this.onClose}
            style={{ float: 'right', marginLeft: '1rem' , color: '#5CA3B6',backgroundColor: 'white', marginRight: '0.5rem',marginTop: '0.3rem',
            border: '3px solid #5CA3B6', borderRadius: '4px' }}>Cancel</a>
          </div>
        </div>
         {this.renderPurchaseOrderForm(purchase_order.purchase_order_id)}
      </div>
    );
  }

  renderSubmitting() {
    const { purchase_order } = this.props;
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <h3>Submit PO#{purchase_order.form_number}</h3>
            <p>Submitting...</p>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns" style={{ textAlign: 'center' }}>
            <img src="/images/gears.gif" />
          </div>
        </div>
      </div>
    );
  }

  renderSubmitted() {
    const { purchase_order } = this.props;
    const { message } = this.state;
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <h3>PO#{purchase_order.form_number} Submitted</h3>
            <p>This purchase order has been successfully submitted to the supplier electronically.</p>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <a className="button" onClick={this.onClose} style={{ float: 'right' }}>Close</a>
          </div>
        </div>
      </div>
    );
  }

  renderError() {
    const { purchase_order } = this.props;
    const { message } = this.state;
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <h3>Submit PO#{purchase_order.form_number}</h3>
            <p>There was a problem submitting the purchase order electronically.</p>
            <p>To send this PO to your supplier via email instead, click the "Email to Supplier" button below and you will be redirected to an email window.</p>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <p>{message}</p>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <a className="button" onClick={this.onClose} target="_blank" rel="opener" href={`/send_email.php?parent_type=purchase_order&parent_id=${purchase_order.purchase_order_id}`} style={{ float: 'right', marginLeft: '1rem' }}>Email to Supplier</a>
            <a className="button" onClick={this.onClose} style={{ float: 'right' }}>Cancel</a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { index, sending } = this.props;
    const { has_error, has_submitted } = this.state;
    return (
      <div className="reveal" style={{ display: 'block', height: '100%', width: '100%' , overflow: 'auto',  maxHeight: 'initial', zIndex: BASE_ZINDEX + index, maxWidth: 'none' }}>
        {sending ? this.renderSubmitting() : (has_error ? this.renderError() : (has_submitted ? this.renderSubmitted() : this.renderSubmit()))}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const purchase_order = getPurchaseOrder(state, ownProps);
  const order_sales_rep_id = purchase_order.order_sales_rep_id ?? state.entities.orders[purchase_order.order_id].sales_rep_id;
  return {
    purchase_order,
    me_contact_id: state.identity.contact_id,
    order_rep_contact_id: state.entities.users[order_sales_rep_id]?.default_contact_id,
    production_rep_contact_id: state.entities.users[purchase_order.sales_rep_id ?? purchase_order.purchase_order_sales_rep_id]?.default_contact_id,
    user_contacts: getUserContactsForEmailList(state),
    sending: state.display.loading.send_po.includes(ownProps.purchase_order_id)
  };
};

const ConnectedSubmitPOPopup = connect(mapStateToProps)(SubmitPOPopup);

export default ConnectedSubmitPOPopup;
